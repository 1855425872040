<template>
  <v-container
    fluid
  >

    <v-row>
      <v-col>
        <v-btn
          @click="dialogRegisterUser = true"
        >
          Register User
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="10"
        >
        <template v-slot:item.context="{ item }"> 

          <v-menu
            offset-y
          >

            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>

            <v-list>

              <v-list-item 
                @click="showActivateCourseDialog(item)" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Activate Course</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item 
                @click="showUpdateUserDialog(item);" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Update User</v-list-item-title>
              </v-list-item>

              <v-list-item 
                @click="showChangePasswordDialog(item);" 
                style="cursor: pointer;"
              >
                <v-list-item-title>Change Password</v-list-item-title>
              </v-list-item>

            </v-list>

          </v-menu>

        </template>       
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialog: Change Password -->

    <v-dialog
      v-model="dialogChangePassword"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Change Password
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="newPassword"
              label="New Password"
            ></v-text-field>
      
          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogChangePassword = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="changePassword"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: Register User -->

    <v-dialog
      v-model="dialogRegisterUser"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Register User
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="registerUsername"
              label="Username"
            ></v-text-field>

            <v-text-field
              v-model="registerEmail"
              label="Email"
            ></v-text-field>

            <v-text-field
              v-model="registerPassword"
              label="Password"
            ></v-text-field>

            <v-text-field
              v-model="registerFirstname"
              label="Firstname"
            ></v-text-field>

            <v-text-field
              v-model="registerSurname"
              label="Surname"
            ></v-text-field>          

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogRegisterUser = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="registerUser"
          >
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog: Update User -->

    <v-dialog
      v-model="dialogUpdateUser"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Update User
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >
            <v-text-field
              v-model="updateUsername"
              label="Username"
            ></v-text-field>

            <v-text-field
              v-model="updateEmail"
              label="Email"
            ></v-text-field>

            <v-text-field
              v-model="updateFirstname"
              label="Firstname"
            ></v-text-field>

            <v-text-field
              v-model="updateSurname"
              label="Surname"
            ></v-text-field>          

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogUpdateUser = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="updateUser"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialog: Activate Course -->

    <v-dialog
      v-model="dialogActivateCourse"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Activate Course
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="activateEmail"
              label="Email"
            ></v-text-field>

            <v-select
              v-model="activateCourseId"
              :items="courses"
              label="Course"
              item-value="courseid"
              item-text="title"
            ></v-select>
      
          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogActivateCourse = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="activateCourse"
          >
            Activate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');
  const UserRepository = RepositoryFactory.get('user');

  export default {
    name: 'Users',

    //components: {
    //  courseExplorer: () => import("@/components/CourseExplorer"),
    //  lessonViewer: () => import("@/components/LessonViewer"),
    //},

    data: () => ({
      headers: [      
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Username',
          value: 'username',
        },  
        {

          text: 'Firstname',
          value: 'firstname',
        },
        {
          text: 'Surname',
          value: 'surname',
        },
        {
          value: 'context',
        },              
      ],      
      users: [],
      courses: [],
      dialogRegisterUser: false,
      registerUsername: '',      
      registerEmail: '',      
      registerPassword: '',      
      registerFirstname: '',      
      registerSurname: '',   
      dialogActivateCourse: false,
      activateEmail: '',
      activateCourseId: 0,   
      dialogChangePassword: false,
      changePasswordUserId: 0,
      newPassword: '',
      dialogUpdateUser: false,
      updateUserId: 0,
      updateUsername: '',      
      updateEmail: '',            
      updateFirstname: '',      
      updateSurname: '',        
    }),

    mounted() {
      this.loadCourses();
      this.loadUsers();
    },

    methods: {

      async activateCourse() {

        const { data } = await UserRepository.activateCourse(
          this.activateEmail, 
          this.activateCourseId
        );
        if (data.success) {                 
          this.activateEmail = ''; 
          this.activateCourseId = 0;  
          this.dialogActivateCourse = false;
        } else {
          errorUtils.showError(data.message);
        }

      },

      async changePassword() {

        const { data } = await UserRepository.changePassword(
          this.changePasswordUserId, 
          this.newPassword
        );
        if (data.success) {                 
          this.changePasswordUserId = 0; 
          this.newPassword = '';  
          this.dialogChangePassword = false;          
        } else {
          errorUtils.showError(data.message);
        }

      },

      async loadCourses() {
        this.courses = [];
        const { data } = await CourseRepository.listCourses();
        if (data.success) {          
          this.courses = data.data.courses;          
        } else {
          errorUtils.showError(data.message);
        }
      },

      async loadUsers() {
        this.users = [];
        const { data } = await UserRepository.listUsers();
        if (data.success) {          
          this.users = data.data.users;
        } else {
          errorUtils.showError(data.message);
        }
      },

      async registerUser() {

        const { data } = await UserRepository.register(
          this.registerUsername, 
          this.registerEmail, 
          this.registerPassword,
          this.registerFirstname, 
          this.registerSurname
        );
        if (data.success) {                 
          this.registerUsername = ''; 
          this.registerEmail = '';  
          this.registerPassword = ''; 
          this.registerFirstname = ''; 
          this.registerSurname = ''; 

          this.dialogRegisterUser = false;
          this.loadUsers();

        } else {
          errorUtils.showError(data.message);
        }

      },

      async updateUser() {

        const { data } = await UserRepository.update(
          this.updateUserId,
          this.updateUsername, 
          this.updateEmail, 
          this.updateFirstname, 
          this.updateSurname
        );
        if (data.success) {                 
          this.dialogUpdateUser = false;
          this.loadUsers();

        } else {
          errorUtils.showError(data.message);
        }

      },

      showActivateCourseDialog(user) {
        this.activateEmail = user.email;  
        this.activateCourseId = 0;      
        this.dialogActivateCourse = true;        
      },

      showChangePasswordDialog(user) {
        this.changePasswordUserId = user.userid;  
        this.newPassword = '';      
        this.dialogChangePassword = true;        
      },

      showUpdateUserDialog(user) {
        this.updateUserId = user.userid;   
        this.updateUsername = user.username; 
        this.updateEmail = user.email; 
        this.updateFirstname = user.firstname; 
        this.updateSurname = user.surname; 
        this.dialogUpdateUser = true;        
      },      

    },

  }
</script>
